import { useMeeting } from "@videosdk.live/react-sdk";
import React, { useState, useEffect } from "react";

export default function Controls(props) {
    const [mics, setMics] = useState([]);
    const [selectedMic, setSelectedMic] = useState("");

    const { leave, toggleMic, toggleWebcam, getMics, changeMic, startRecording, stopRecording } = useMeeting({
        onWebcamRequested: ({ accept, reject, participantId }) => {
            // callback function to accept the request
            accept();

            // callback function to reject the request
            reject();
        },
        onMicRequested: ({ accept, reject, participantId }) => {
            // callback function to accept the request
            accept();

            // callback function to reject the request
            reject();
        },
    });


    const handleStartRecording = () => {
        // Start Recording
        // If you don't have a `webhookUrl` or `awsDirPath`, you should pass null.
        startRecording(null, null, {
            layout: {
                type: "GRID",
                priority: "SPEAKER",
                gridSize: 4,
            },
            theme: "DARK",
            mode: "video-and-audio",
            quality: "high",
            orientation: "landscape",
        });
    };

    const handleStopRecording = () => {
        // Stop Recording
        stopRecording();
    };




    //Method to get the mics and load in our state
    const handleGetMics = async () => {
        const mics = await getMics();
        setMics(mics);
    };



    useEffect(() => {
        handleGetMics();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //Chanign the mic with the selected deviceId
    const handleChangeMic = (event) => {
        changeMic(event.target.value);
        setSelectedMic(event.target.value);
    };




    return (
        <div>
            <button onClick={() => leave()}>Leave</button>
            <button onClick={() => toggleMic()}>toggleMic</button>
            <button onClick={handleStartRecording}>Start Recording</button>
            <button onClick={handleStopRecording}>Stop Recording</button>
            <select value={selectedMic} onChange={handleChangeMic}>
                {mics.map((mic) => {
                    return <option key={mic.deviceId} value={mic.deviceId}>{mic.label}</option>;
                })}
            </select>

            <button onClick={() => toggleWebcam()}>toggleWebcam</button>
            <button onClick={props.handleEnableScreenShare}>Enable Screen Share</button>
            <button onClick={props.handleDisableScreenShare}>Disable Screen Share</button>
            <button onClick={props.handleToggleScreenShare}>Toggle Screen Share</button>
        </div>
    );
}  