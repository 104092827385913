import "./App.css";
import React, { useState } from "react";
import { MeetingProvider, MeetingConsumer } from "@videosdk.live/react-sdk";
import { authToken, createMeeting } from "./API";
import MeetingView from "./components/MeetingView";
import JoinScreen from "./components/JoinScreen";
import { useNavigate } from "react-router-dom";


function App() {
  const [meetingId, setMeetingId] = useState(null);
  const navigate = useNavigate();

  const getMeetingAndToken = async (id) => {
    const meetingId =
      id == null ? await createMeeting({ token: authToken }) : id;
    setMeetingId(meetingId);
    navigate('/join/' + meetingId);
  };

  const onMeetingLeave = () => {
    setMeetingId(null);
  };



  return authToken && meetingId ? (
    <MeetingProvider
      config={{
        meetingId,
        micEnabled: true,
        webcamEnabled: true,
        name: "Your Name",
      }}
      token={authToken}
    >
      <MeetingConsumer>
        {() => (
          <MeetingView meetingId={meetingId} onMeetingLeave={onMeetingLeave} />
        )}
      </MeetingConsumer>
    </MeetingProvider>
  ) : (
    <JoinScreen getMeetingAndToken={getMeetingAndToken} />
  );
}

export default App;