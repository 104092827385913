import React, { useEffect, useState , useCallback} from "react";
import { MeetingProvider, MeetingConsumer } from "@videosdk.live/react-sdk";
import { authToken } from "../API";
import MeetingView from "../components/MeetingView";
import { useParams } from "react-router-dom";


function Join() {
    const [meetingId, setMeetingId] = useState(null);
    const param = useParams();
   
    const [config, setConfig] = useState([{
        meetingId,
        micEnabled: true,
        webcamEnabled: true,
        name: "Your Name",
    }]);
    const getMeetingAndToken = async () => {
        setMeetingId(param.meetingId);
        setConfig((config.map(artwork => {
            if (artwork.meetingId === null) {
                // Create a *new* object with changes
                return { ...artwork, meetingId: param.meetingId };
            } else {
                // No changes
                return { ...artwork, meetingId: param.meetingId };
            }
        })));
    };


    const toggleWebcamStatus = async () => {
        setConfig((config.map(artwork => {
            if (artwork.webcamEnabled === true) {
                // Create a *new* object with changes
                return { ...artwork, webcamEnabled: false };
            } else {
                // No changes
                return { ...artwork, webcamEnabled: true };
            }
        })));
    }

    const toggleMicStatus = async () => {
        setConfig((config.map(artwork => {
            if (artwork.micEnabled === true) {
                // Create a *new* object with changes
                return { ...artwork, micEnabled: false };
            } else {
                // No changes
                return { ...artwork, micEnabled: true };
            }
        })));
    }

    const setDisplayName = async (newName) => {
        setConfig((config.map(artwork => {
                 // Create a *new* object with changes
                return { ...artwork, name: newName };
         })));
    };

    const onMeetingLeave = () => {
        setMeetingId(null);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const loadMeeting = useCallback( async () => {
        await getMeetingAndToken(meetingId);
    });

    useEffect(() => {
        loadMeeting();
        console.log("loaded");
    // eslint-disable-next-line react-hooks/exhaustive-deps
    } ,[]);

    return authToken && meetingId ? (
        <MeetingProvider
            config={config[0]}
            token={authToken}
        >
            <MeetingConsumer>
                {() => (
                    <MeetingView setDisplayName={setDisplayName} toggleMicStatus={toggleMicStatus} toggleWebcamStatus={toggleWebcamStatus} config={config[0]} meetingId={meetingId} onMeetingLeave={onMeetingLeave} />
                )}
            </MeetingConsumer>
        </MeetingProvider>
    ) : (
        <button onClick={loadMeeting}>Loadding..</button>
    );
}

export default Join;