/* eslint-disable eqeqeq */
import React, { useState } from "react";
import { useMeeting } from "@videosdk.live/react-sdk";
import ParticipantView from "./ParticipantView";
import Controls from "./Controls";
import { Notification } from "./Notification";
import PresenterView from "./PresenterView";

function MeetingView(props) {
  const [joined, setJoined] = useState(null);
  const { join } = useMeeting();
  //Getting the screen-share methods from hook
  const { enableScreenShare, disableScreenShare, toggleScreenShare } = useMeeting();
  const { presenterId } = useMeeting();

  const { participants } = useMeeting({
    onPresenterChanged,
    onMeetingJoined: () => {
      setJoined("JOINED");
    },
    onMeetingLeft: () => {
      props.onMeetingLeave();
    },
    onSpeakerChanged: (activeSpeakerId) => {
      console.log("Active Speaker participantId", activeSpeakerId);
    },
  });
  const joinMeeting = () => {
    console.log(props.meetingId);
    setJoined("JOINING");
    join();
  };

  //Callback for when the presenter changes
  function onPresenterChanged(presenterId) {
    if (presenterId) {
      console.log(presenterId, "started screen share");
    } else {
      console.log("someone stopped screen share");
    }
  }


  const handleEnableScreenShare = () => {
    // Enabling screen share
    enableScreenShare();
  };

  const handleDisableScreenShare = () => {
    // Disabling screen share
    disableScreenShare();
  };

  const handleToggleScreenShare = () => {
    // Toggling screen share
    toggleScreenShare();
  };

  return (
    <div className="container">
       <img  height={"70px"}
          width={"250px"} src={'../meet.png'} alt={''} />
      <h3>Meeting Id: {props.meetingId}</h3>
      {joined && joined == "JOINED" ? (
        <div>
          <Controls
            handleEnableScreenShare={handleEnableScreenShare}
            handleDisableScreenShare={handleDisableScreenShare}
            handleToggleScreenShare={handleToggleScreenShare}
          />
          {[...participants.keys()].map((participantId) => (
            <ParticipantView
              participantId={participantId}
              key={participantId}
            />

          ))}
          <Notification />
          {presenterId && <PresenterView presenterId={presenterId} />}
        </div>
      ) : joined && joined == "JOINING" ? (
        <p>Joining the meeting...</p>
      ) : (
        <>
          <p> Display Name :   <input
          type="text"
          value={props.config.name} 
          placeholder="Enter Meeting Id"
          onChange={(e) => {
            props.setDisplayName(e.target.value);
          }}
        /> </p>
          <p> Mic Status : {props.config.micEnabled ? 'ON' : 'OFF'} </p>
          <p> Webcam Status : {props.config.webcamEnabled ? 'ON' : 'OFF'} </p>

          <button onClick={props.toggleWebcamStatus}>(On/Off) Webcam</button>
          <button onClick={props.toggleMicStatus}>(On/Off) Mic</button>
          <br />
          <button onClick={joinMeeting}>Join Meeting</button>
        </>

      )}
    </div>
  );
}

export default MeetingView;