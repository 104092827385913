import React, { useEffect, useMemo, useRef } from "react";
import ReactPlayer from "react-player";
import { useParticipant } from "@videosdk.live/react-sdk";

export default function ParticipantView(props) {
  const micRef = useRef(null);

  const { webcamStream, remove, micStream, webcamOn, micOn, isLocal, displayName, enableWebcam, disableWebcam, enableMic, disableMic } =
    useParticipant(props.participantId,
      onStreamEnabled,
      onStreamDisabled, onMediaStatusChanged);

  //Callback for when the participant starts a stream
  function onStreamEnabled(stream) {
    if (stream.kind === 'share') {
      console.log("Share Stream On: onStreamEnabled", stream);
    }
    if (stream.kind === 'audio') {
      console.log("Audio Stream On: onStreamEnabled", stream);
    }
  }

  //Callback for when the participant stops a stream
  function onStreamDisabled(stream) {
    if (stream.kind === 'share') {
      console.log("Share Stream Off: onStreamDisabled", stream);
    }
    if (stream.kind === 'audio') {
      console.log("Audio Stream Off: onStreamDisabled", stream);
    }
  }

  //Callback for when participants media gets changed
  function onMediaStatusChanged(data) {
    const { kind, newStatus } = data;
    console.log("Participant Media Kind: ", kind, " newStatus: ", newStatus);
  }

  const handleEnableWebcam = () => {
    // This will emit an event called "onWebcamRequested" to that particular participant
    enableWebcam();
  };

  const handleEnableMic = () => {
    // This will emit an event called "onMicRequested" to that particular participant
    enableMic();
  };

  const handleDisableWebcam = () => {
    // This will disable the webcam of that particular participant
    disableWebcam();
  };

  const handleDisableMic = () => {
    // This will disable the mic of that particular participant
    disableMic();
  };

  const handleRemoveParticipant = () => {
    // Remove participant from active session
    // This will emit an event called "onParticipantLeft" to that particular participant
    remove();
  };


  const videoStream = useMemo(() => {
    if (webcamOn && webcamStream) {
      const mediaStream = new MediaStream();
      mediaStream.addTrack(webcamStream.track);
      return mediaStream;
    }
  }, [webcamStream, webcamOn]);



  useEffect(() => {
    if (micRef.current) {
      if (micOn && micStream) {
        const mediaStream = new MediaStream();
        mediaStream.addTrack(micStream.track);

        micRef.current.srcObject = mediaStream;
        micRef.current
          .play()
          .catch((error) =>
            console.error("videoElem.current.play() failed", error)
          );
      } else {
        micRef.current.srcObject = null;
      }
    }
  }, [micStream, micOn]);

  return (
    <div key={props.participantId}>
      <p>
        Participant: {displayName} | Webcam: {webcamOn ? "ON" : "OFF"} | Mic:{" "}
        {micOn ? "ON" : "OFF"}
      </p>
      <button onClick={handleEnableWebcam}>Enable Webcam</button>
      <button onClick={handleEnableMic}>Enable Mic</button>
      <button onClick={handleDisableWebcam}>Disable Webcam</button>

      { displayName !== 'Mohamed Mostafa' ? (
       <>
         <button onClick={handleDisableMic}>Disable Mic</button>
         <button onClick={handleRemoveParticipant}>Remove Participant</button></>
       ) : null}

      <audio ref={micRef} autoPlay muted={isLocal} />
      {webcamOn ?  (
        <center> 
        <ReactPlayer
          //
          playsinline // very very imp prop
          pip={false}
          light={false}
          controls={false}
          muted={true}
          playing={true}
          //
          url={videoStream}
          //
          height={"200px"}
          width={"300px"}
          onError={(err) => {
            console.log(err, "participant video error");
          }}
        />
        </center>
      ) : <div> 
        <img  height={"200px"}
          width={"300px"} src={'https://ui-avatars.com/api/?background=random&name=' + displayName} alt={''} />
        </div>}
    </div>
  );
}