import React, {  useState  } from "react";

export default function JoinScreen({ getMeetingAndToken }) {
    const [meetingId, setMeetingId] = useState(null);

    const onClick = async () => {
      await getMeetingAndToken(meetingId);
      console.log(meetingId);

    };
    return (
      <div>
         <img  height={"70px"}
          width={"250px"} src={'../meet.png'} alt={''} />
          <br />
        <input
          type="text"
          placeholder="Enter Meeting Id"
          onChange={(e) => {
            setMeetingId(e.target.value);
          }}
        />
        <button onClick={onClick}>Join</button>
        {" or "}
        <button onClick={onClick}>Create Meeting</button>
      </div>
    );
  }  